import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));

/****Pages******/
const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1.js"));
const Login = lazy(() => import("../screens/Login.js")); // Login screen

/*****Tables******/
const BasicTable = lazy(() => import("../views/tables/BasicTable.js"));

// Form elements
const ExAutoComplete = lazy(() =>
  import("../views/FormElements/ExAutoComplete.js")
);
const ExButton = lazy(() => import("../views/FormElements/ExButton.js"));
const ExCheckbox = lazy(() => import("../views/FormElements/ExCheckbox.js"));
const ExRadio = lazy(() => import("../views/FormElements/ExRadio.js"));
const ExSlider = lazy(() => import("../views/FormElements/ExSlider.js"));
const ExSwitch = lazy(() => import("../views/FormElements/ExSwitch.js"));

// Form layouts
const FormLayouts = lazy(() => import("../views/FormLayouts/FormLayouts.js"));

// Screens
const MukhyVibhago = lazy(() => import("../screens/MukhyVibhago.js"));
const AddMukhyVibhago = lazy(() => import("../screens/AddMukhyVibhago.js"));
const PathMaterial = lazy(() => import("../screens/PathMaterial.js"));
const Dhoran = lazy(() => import("../screens/Dhoran.js"));
const SatraAndPravah = lazy(() => import("../screens/SatraAndPravah.js"));
const Pustako = lazy(() => import("../screens/Pustako.js"));
const AddPustak = lazy(() => import("../screens/AddPustak.js"));
const SvaAdhyanPothi = lazy(() => import("../screens/SvaAdhyanPothi.js"));
const AddSvaAdhyanPothi = lazy(() => import("../screens/AddSvaAdhyanPothi.js"));
const Vishay = lazy(() => import("../screens/Vishay.js"));
const AddDhoran = lazy(() => import("../screens/AddDhoran.js"));
const AddPathMaterial = lazy(() => import("../screens/AddPathMaterial.js"));
const AddSatraAndPravah = lazy(() => import("../screens/AddSatraAndPravah.js"));
const AddVishay = lazy(() => import("../screens/AddVishay.js"));
const AddNavuJaniyeList = lazy(() => import("../screens/AddNavuJaniyeList.js"));
const NavuJaniyeList = lazy(() => import("../screens/NavuJaniyeList.js"));
const NavuJaniyeMaterialList = lazy(() =>
  import("../screens/NavuJaniyeMaterialList.js")
);
const AddNavuJaniyeMaterial = lazy(() =>
  import("../screens/AddNavuJaniyeMaterial.js")
);
const NavuJaniye = lazy(() => import("../screens/NavuJaniye.js"));
const AnyMaterial = lazy(() => import("../screens/AnyMaterial.js"));
const AddAnyMaterial = lazy(() => import("../screens/AddAnyMaterial.js"));
const YoutubeChannels = lazy(() => import("../screens/YoutubeChannels.js"));
const AddYoutubeChannels = lazy(() =>
  import("../screens/AddYoutubeChannels.js")
);
const OtherApps = lazy(() => import("../screens/OtherApps.js"));
const AddOtherApps = lazy(() => import("../screens/AddOtherApps.js"));
const AppLinks = lazy(() => import("../screens/AppLinks.js"));
const AddAppLinks = lazy(() => import("../screens/AddAppLinks.js"));

/*****Routes******/

const isAuthenticated = () => {
  return !!localStorage.getItem("authToken"); // Example
};

console.log("isAuthenticated()--------", localStorage.getItem("authToken"));

const ThemeRoutes = [
  {
    path: "/login", // Login route
    element: <Login />,
  },
  {
    path: "/",
    element: isAuthenticated() ? <FullLayout /> : <Navigate to="/login" />,
    children: [
      { path: "/", element: <Navigate to="dashboards/dashboard1" /> },
      {
        path: "dashboards/dashboard1",
        element: <Dashboard1 />,
      },
      { path: "tables/basic-table", element: <BasicTable /> },
      { path: "form-layouts/form-layouts", element: <FormLayouts /> },
      { path: "form-elements/autocomplete", element: <ExAutoComplete /> },
      { path: "form-elements/button", element: <ExButton /> },
      { path: "form-elements/checkbox", element: <ExCheckbox /> },
      { path: "form-elements/radio", element: <ExRadio /> },
      { path: "form-elements/slider", element: <ExSlider /> },
      { path: "form-elements/switch", element: <ExSwitch /> },
      { path: "MukhyVibhago", element: <MukhyVibhago /> },
      { path: "AddMukhyVibhago", element: <AddMukhyVibhago /> },
      { path: "Dhoran", element: <Dhoran /> },
      { path: "AddDhoran", element: <AddDhoran /> },
      { path: "PathMaterial", element: <PathMaterial /> },
      { path: "AddPathMaterial", element: <AddPathMaterial /> },
      { path: "SatraAndPravah", element: <SatraAndPravah /> },
      { path: "AddSatraAndPravah", element: <AddSatraAndPravah /> },
      { path: "Vishay", element: <Vishay /> },
      { path: "AddVishay", element: <AddVishay /> },
      { path: "NavuJaniye", element: <NavuJaniye /> },
      { path: "AddNavuJaniyeList", element: <AddNavuJaniyeList /> },
      { path: "NavuJaniyeList", element: <NavuJaniyeList /> },
      { path: "AddNavuJaniyeMaterial", element: <AddNavuJaniyeMaterial /> },
      { path: "NavuJaniyeMaterialList", element: <NavuJaniyeMaterialList /> },
      { path: "AnyMaterial", element: <AnyMaterial /> },
      { path: "AddAnyMaterial", element: <AddAnyMaterial /> },
      { path: "YoutubeChannels", element: <YoutubeChannels /> },
      { path: "AddYoutubeChannels", element: <AddYoutubeChannels /> },
      { path: "OtherApps", element: <OtherApps /> },
      { path: "AddOtherApps", element: <AddOtherApps /> },
      { path: "AppLinks", element: <AppLinks /> },
      { path: "AddAppLinks", element: <AddAppLinks /> },
      { path: "Pustako", element: <Pustako /> },
      { path: "AddPustak", element: <AddPustak /> },
      { path: "SvaAdhyanPothi", element: <SvaAdhyanPothi /> },
      { path: "AddSvaAdhyanPothi", element: <AddSvaAdhyanPothi /> },
    ],
  },
];

export default ThemeRoutes;
